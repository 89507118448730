/* eslint-disable no-underscore-dangle */
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import {
  EthereumRpcError,
  getMessageFromCode,
  serializeError,
} from 'eth-rpc-errors';
import { IEthereumSigner, INearSigner, ISigner } from '../signers';

import { AddressType, PureFIErrorCodes, PureFIRuleErrorCodes } from '../enums';

import { OriginalError, PureFIError, PureFIRuleError } from '../error';

import {
  isPureFIPayload,
  isPureFIAddress,
  isPureFIEthereumPayload,
  isVerifyRulePayload,
  isVerifyRuleLegacyPayload,
  isSigner,
  isEthereumSigner,
  isNearSigner,
  isVerifyRuleEthereumLegacyPayload,
  isVerifyRuleEthereumPayload,
  isVerifyRuleNearLegacyPayload,
  isVerifyRuleNearPayload,
} from '../helpers';

import {
  CheckRiskResponse,
  DownloadReportResponse,
  ErrorResponse,
  PureFIAddress,
  PureFIPayload,
  VerifyRulePayload,
  VerifyRuleResponse,
  VerifyRuleEthereumPayload,
  VerifyRuleNearPayload,
  NearSignature,
} from '../types';

class PureFI {
  private static readonly backendUrl = 'https://issuer.app.purefi.io';

  private static readonly backendUrlB2B =
    'https://business-backend.app.purefi.io';

  private static signer?: ISigner;

  private constructor() {
    // is not in use
  }

  /**
   * Sets a signer. The signer must implement {@link ISigner} interface
   *
   * Provided signer will be used later on to sign messages within {@link checkRisk} and {@link downloadReport} methods
   *
   * **There is one exception**
   *
   * In case of {@link checkRisk} and {@link downloadReport} overloads
   * that get {@link PureFIPayload} as an argument, mentioned methods assume you {@link ISigner.signMessage | signed} a message by **yourself**
   * (probably you have a specific scenario around signing), so you don't need to set any signer at all in this particular case
   *
   * *All for the sake of flexibility*
   *
   * @param signer - an instance that implements the {@link ISigner} interface
   */
  public static setSigner(signer: ISigner): void {
    PureFI.signer = signer;
  }

  /**
   * Sets signer to undefined
   */
  public static unsetSigner(): void {
    PureFI.signer = undefined;
  }

  /**
   * Returns current signer
   */
  public static getSigner(): ISigner | undefined {
    return PureFI.signer;
  }

  /**
   * Performs AML risk score screening
   *
   * It assumes that provided address is the address of a wallet
   * @param param - An address to screen
   */
  public static async checkRisk(param: string): Promise<CheckRiskResponse>;

  /**
   * Performs AML risk score screening
   *
   * It assumes that provided addresses are wallet-kind addresses
   * @param param - An array of addresses to screen
   */
  public static async checkRisk(param: string[]): Promise<CheckRiskResponse[]>;

  /**
   * Performs AML risk score screening
   *
   * Allows to specify what type of address you screen
   *
   * @param param - A PureFIAddress to screen
   */
  public static async checkRisk(
    param: PureFIAddress
  ): Promise<CheckRiskResponse>;

  /**
   * Performs AML risk score screening
   *
   * Allows to specify what type of addresses you screen
   *
   * @param param - An array of PureFIAddress'es to screen
   */
  public static async checkRisk(
    param: PureFIAddress[]
  ): Promise<CheckRiskResponse[]>;

  /**
   * Performs AML risk score screening
   *
   * Consider using this overload if you want to have more control
   *
   * over message signing and handle this process by **yourself**
   *
   * In this particular case you don't need {@link setSigner | to set} any signers
   */
  public static async checkRisk(
    param: PureFIPayload
  ): Promise<CheckRiskResponse[]>;

  public static async checkRisk(
    param: string | string[] | PureFIAddress | PureFIAddress[] | PureFIPayload
  ): Promise<CheckRiskResponse | CheckRiskResponse[]> {
    let address: string | undefined;
    let addresses: string[] | undefined;
    let purefiAddress: PureFIAddress | undefined;
    let purefiAddresses: PureFIAddress[] | undefined;
    let payload: PureFIPayload | undefined;

    if (typeof param === 'string') {
      if (param.length > 0) {
        address = param as string;
      } else {
        throw new PureFIError(
          PureFIErrorCodes.VALIDATION,
          'Empty address provided'
        );
      }
      address = param;
    } else if (isPureFIPayload(param)) {
      payload = param as PureFIPayload;
    } else if (isPureFIAddress(param)) {
      purefiAddress = param as PureFIAddress;
    } else if (Array.isArray(param)) {
      const theParam: Array<string | PureFIAddress> = param;
      if (theParam.every((item) => typeof item === 'string')) {
        addresses = theParam as string[];
      } else if (theParam.every((item) => isPureFIAddress(item))) {
        purefiAddresses = theParam as PureFIAddress[];
      } else {
        throw new PureFIError(
          PureFIErrorCodes.VALIDATION,
          'Unsupported payload format provided'
        );
      }
    } else {
      throw new PureFIError(
        PureFIErrorCodes.VALIDATION,
        'Unsupported payload format provided'
      );
    }

    let isBulk: boolean;
    let message: string;
    let signature = '';

    if (typeof payload !== 'undefined') {
      if (!isPureFIEthereumPayload(payload)) {
        throw new PureFIError(
          PureFIErrorCodes.VALIDATION,
          'Unsupported payload format provided'
        );
      }

      let parsedMessage: PureFIAddress[] | undefined;
      try {
        parsedMessage = JSON.parse(payload.message);
      } catch (e) {
        throw new PureFIError(
          PureFIErrorCodes.VALIDATION,
          'Unsupported payload format provided'
        );
      }

      if (
        Array.isArray(parsedMessage) &&
        parsedMessage.every((item) => isPureFIAddress(item))
      ) {
        if (parsedMessage.length > 0) {
          isBulk = true;
          message = payload.message;
          signature = payload.signature;
        } else {
          throw new PureFIError(
            PureFIErrorCodes.VALIDATION,
            'Empty payload provided'
          );
        }
      } else {
        throw new PureFIError(
          PureFIErrorCodes.VALIDATION,
          'Unsupported payload format provided'
        );
      }
    } else if (
      typeof addresses !== 'undefined' ||
      typeof purefiAddresses !== 'undefined'
    ) {
      isBulk = true;
      if (typeof addresses !== 'undefined') {
        message = JSON.stringify(
          addresses.map((item) => ({ address: item, type: AddressType.WALLET }))
        );
      } else {
        message = JSON.stringify(purefiAddresses);
      }
    } else if (
      typeof address !== 'undefined' ||
      typeof purefiAddress !== 'undefined'
    ) {
      isBulk = false;
      if (typeof address !== 'undefined') {
        message = JSON.stringify([{ address, type: AddressType.WALLET }]);
      } else {
        message = JSON.stringify([purefiAddress]);
      }
    } else {
      throw new PureFIError(
        PureFIErrorCodes.VALIDATION,
        'Unsupported payload format provided'
      );
    }

    if (signature === '') {
      if (!isSigner(PureFI.signer)) {
        throw new PureFIError(
          PureFIErrorCodes.CONFIGURATION,
          'The signer is missing'
        );
      }

      if (!isEthereumSigner(PureFI.signer)) {
        throw new PureFIError(
          PureFIErrorCodes.CONFIGURATION,
          'Unsupported signer'
        );
      }

      try {
        signature = await PureFI.signer.signMessage(message);
      } catch (error) {
        const rpcError = error as EthereumRpcError<OriginalError>;
        const {
          code,
          data,
          message: rpcErrorMessage,
        } = serializeError(rpcError);

        const errorData = data as OriginalError;
        const fallbackMessage =
          errorData?.originalError?.error?.message ||
          errorData?.originalError?.reason ||
          rpcErrorMessage;

        throw new PureFIError(
          PureFIErrorCodes.PROVIDER,
          getMessageFromCode(code, fallbackMessage)
        );
      }
    }

    const purefiPayload: PureFIPayload = {
      message,
      signature,
    };
    const result = await PureFI._checkRisk(purefiPayload, isBulk);
    return result;
  }

  private static async _checkRisk(
    payload: PureFIPayload,
    isBulk = true
  ): Promise<CheckRiskResponse | CheckRiskResponse[]> {
    try {
      const response = await axios.post<
        PureFIPayload,
        AxiosResponse<CheckRiskResponse[]>
      >(`${PureFI.backendUrl}/v2/monitoring`, payload);

      return !isBulk ? response.data[0] : response.data;
    } catch (error) {
      if (error instanceof Error) {
        if (axios.isAxiosError(error)) {
          const axiosError = error as AxiosError<ErrorResponse>;
          if (axiosError && axiosError.response) {
            const { data, status } = axiosError.response;
            switch (status) {
              case 400: {
                const errorResponse = data as ErrorResponse;
                switch (errorResponse.errorCode) {
                  case 20: {
                    throw new PureFIError(
                      PureFIErrorCodes.VALIDATION,
                      'Unsupported payload format provided'
                    );
                  }
                  case 10:
                  case 40: {
                    throw new PureFIError(
                      PureFIErrorCodes.INTERNAL,
                      'Internal error occurred'
                    );
                  }
                  default: {
                    throw new PureFIError(
                      PureFIErrorCodes.INTERNAL,
                      'Unknown internal error occurred'
                    );
                  }
                }
              }
              case 403: {
                throw new PureFIError(
                  PureFIErrorCodes.FORBIDDEN,
                  'Insufficient UFI balance'
                );
              }
              default: {
                throw new PureFIError(
                  PureFIErrorCodes.INTERNAL,
                  'Internal error occurred'
                );
              }
            }
          } else {
            throw new PureFIError(
              PureFIErrorCodes.UNKNOWN,
              'Network error occurred'
            );
          }
        } else {
          throw new PureFIError(
            PureFIErrorCodes.UNKNOWN,
            'Unexpected error occurred'
          );
        }
      } else {
        throw new PureFIError(
          PureFIErrorCodes.UNKNOWN,
          'Unexpected error occurred'
        );
      }
    }
  }

  /**
   * Generates PureFI VC Certificate in .pdf format
   *
   * It assumes that provided address is the address of a wallet
   * @param param - An address to screen
   */
  public static async downloadReport(
    param: string
  ): Promise<DownloadReportResponse>;

  /**
   * Generates PureFI VC Certificate in .pdf format
   *
   * It assumes that provided addresses are wallet-kind addresses
   * @param param - An array of addresses to screen
   */
  public static async downloadReport(
    param: string[]
  ): Promise<DownloadReportResponse>;

  /**
   * Generates PureFI VC Certificate in .pdf format
   *
   * Allows to specify type of the address
   *
   * @param param - A PureFIAddress to generate a report
   */
  public static async downloadReport(
    param: PureFIAddress
  ): Promise<DownloadReportResponse>;

  /**
   * Generates PureFI VC Certificate in .pdf format
   *
   * Allows to specify types of the addresses
   *
   * @param param - An array of PureFIAddress'es to generate a report
   */
  public static async downloadReport(
    param: PureFIAddress[]
  ): Promise<DownloadReportResponse>;

  /**
   * Generates PureFI VC Certificate in .pdf format
   *
   * Consider using this overload if you want to have more control
   *
   * over message signing and handle this process by **yourself**
   *
   * In this particular case you don't need {@link setSigner | to set} any signers
   */
  public static async downloadReport(
    param: PureFIPayload
  ): Promise<DownloadReportResponse>;

  public static async downloadReport(
    param: string | string[] | PureFIAddress | PureFIAddress[] | PureFIPayload
  ): Promise<DownloadReportResponse> {
    let address: string | undefined;
    let addresses: string[] | undefined;
    let purefiAddress: PureFIAddress | undefined;
    let purefiAddresses: PureFIAddress[] | undefined;
    let payload: PureFIPayload | undefined;

    if (typeof param === 'string') {
      if (param.length > 0) {
        address = param as string;
      } else {
        throw new PureFIError(
          PureFIErrorCodes.VALIDATION,
          'Empty address provided'
        );
      }
      address = param;
    } else if (isPureFIPayload(param)) {
      payload = param as PureFIPayload;
    } else if (isPureFIAddress(param)) {
      purefiAddress = param as PureFIAddress;
    } else if (Array.isArray(param)) {
      const theParam: Array<string | PureFIAddress> = param;
      if (theParam.every((item) => typeof item === 'string')) {
        addresses = theParam as string[];
      } else if (theParam.every((item) => isPureFIAddress(item))) {
        purefiAddresses = theParam as PureFIAddress[];
      } else {
        throw new PureFIError(
          PureFIErrorCodes.VALIDATION,
          'Unsupported payload format provided'
        );
      }
    } else {
      throw new PureFIError(
        PureFIErrorCodes.VALIDATION,
        'Unsupported payload format provided'
      );
    }

    let message: string;
    let signature = '';

    if (typeof payload !== 'undefined') {
      if (!isPureFIEthereumPayload(payload)) {
        throw new PureFIError(
          PureFIErrorCodes.VALIDATION,
          'Unsupported payload format provided'
        );
      }

      let parsedMessage: PureFIAddress[] | undefined;
      try {
        parsedMessage = JSON.parse(payload.message);
      } catch (e) {
        throw new PureFIError(
          PureFIErrorCodes.VALIDATION,
          'Unsupported payload format provided'
        );
      }

      if (Array.isArray(parsedMessage)) {
        if (parsedMessage.length > 0) {
          message = payload.message;
          signature = payload.signature;
        } else {
          throw new PureFIError(
            PureFIErrorCodes.VALIDATION,
            'Unsupported payload format provided'
          );
        }
      } else {
        throw new PureFIError(
          PureFIErrorCodes.VALIDATION,
          'Unsupported payload format provided'
        );
      }
    } else if (typeof addresses !== 'undefined') {
      message = JSON.stringify(
        addresses.map((item) => ({ address: item, type: AddressType.WALLET }))
      );
    } else if (typeof purefiAddresses !== 'undefined') {
      message = JSON.stringify(purefiAddresses);
    } else if (typeof address !== 'undefined') {
      message = JSON.stringify([{ address, type: AddressType.WALLET }]);
    } else if (typeof purefiAddress !== 'undefined') {
      message = JSON.stringify([purefiAddress]);
    } else {
      throw new PureFIError(
        PureFIErrorCodes.VALIDATION,
        'Unsupported payload format provided'
      );
    }

    if (signature === '') {
      if (!isSigner(PureFI.signer)) {
        throw new PureFIError(
          PureFIErrorCodes.CONFIGURATION,
          'The signer is missing'
        );
      }

      try {
        signature = await PureFI.signer.signMessage(message);
      } catch (error) {
        const rpcError = error as EthereumRpcError<OriginalError>;
        const {
          code,
          data,
          message: rpcErrorMessage,
        } = serializeError(rpcError);

        const errorData = data as OriginalError;
        const fallbackMessage =
          errorData?.originalError?.error?.message ||
          errorData?.originalError?.reason ||
          rpcErrorMessage;

        throw new PureFIError(
          PureFIErrorCodes.PROVIDER,
          getMessageFromCode(code, fallbackMessage)
        );
      }
    }

    const purefiPayload: PureFIPayload = {
      message,
      signature,
    };
    const result = await PureFI._downloadReport(purefiPayload);
    return result;
  }

  private static async _downloadReport(
    payload: PureFIPayload
  ): Promise<DownloadReportResponse> {
    try {
      const generateResponse = await axios.post<
        PureFIPayload,
        AxiosResponse<{ link: string }>
      >(`${PureFI.backendUrl}/v2/report`, payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const downloadResponse = await axios.get(generateResponse.data.link, {
        headers: {
          'Content-Type': 'application/pdf',
        },
        responseType: 'arraybuffer',
      });

      const response: DownloadReportResponse = {
        buffer: downloadResponse.data,
      };

      return response;
    } catch (error) {
      if (error instanceof Error) {
        if (axios.isAxiosError(error)) {
          const axiosError = error as AxiosError<ErrorResponse>;
          if (axiosError && axiosError.response) {
            const { data, status } = axiosError.response;
            switch (status) {
              case 400: {
                const errorResponse = data as ErrorResponse;
                switch (errorResponse.errorCode) {
                  case 20: {
                    throw new PureFIError(
                      PureFIErrorCodes.VALIDATION,
                      'Unsupported payload format provided'
                    );
                  }
                  case 10:
                  case 40: {
                    throw new PureFIError(
                      PureFIErrorCodes.INTERNAL,
                      'Internal error occurred'
                    );
                  }
                  default: {
                    throw new PureFIError(
                      PureFIErrorCodes.INTERNAL,
                      'Unknown internal error occurred'
                    );
                  }
                }
              }
              case 403: {
                throw new PureFIError(
                  PureFIErrorCodes.FORBIDDEN,
                  'Insufficient UFI balance'
                );
              }
              default: {
                throw new PureFIError(
                  PureFIErrorCodes.INTERNAL,
                  'Internal error occurred'
                );
              }
            }
          } else {
            throw new PureFIError(
              PureFIErrorCodes.UNKNOWN,
              'Network error occurred'
            );
          }
        } else {
          throw new PureFIError(
            PureFIErrorCodes.UNKNOWN,
            'Unexpected error occurred'
          );
        }
      } else {
        throw new PureFIError(
          PureFIErrorCodes.UNKNOWN,
          'Unexpected error occurred'
        );
      }
    }
  }

  public static async verifyRule(
    param: PureFIPayload,
    projectId?: string
  ): Promise<VerifyRuleResponse>;

  public static async verifyRule(
    param: VerifyRulePayload,
    projectId?: string
  ): Promise<VerifyRuleResponse>;

  public static async verifyRule(
    param: PureFIPayload | VerifyRulePayload,
    projectId?: string
  ): Promise<VerifyRuleResponse> {
    let payload: PureFIPayload | undefined;

    if (isPureFIPayload(param)) {
      payload = param as PureFIPayload;
    } else if (isVerifyRulePayload(param)) {
      if (!isSigner(PureFI.signer)) {
        throw new PureFIError(
          PureFIErrorCodes.CONFIGURATION,
          'The signer is missing'
        );
      }

      if (
        isVerifyRuleEthereumLegacyPayload(param) ||
        isVerifyRuleEthereumPayload(param)
      ) {
        if (!isEthereumSigner(PureFI.signer)) {
          throw new PureFIError(
            PureFIErrorCodes.CONFIGURATION,
            'Signer/payload mismatch'
          );
        }

        const rulePayload = param as VerifyRuleEthereumPayload;
        const message = JSON.stringify(rulePayload);
        let signature: string;

        const ethereumSigner = PureFI.signer as IEthereumSigner;

        try {
          signature = await ethereumSigner.signMessage(message);
        } catch (error) {
          const rpcError = error as EthereumRpcError<OriginalError>;
          const {
            code,
            data,
            message: rpcErrorMessage,
          } = serializeError(rpcError);

          const errorData = data as OriginalError;
          const fallbackMessage =
            errorData?.originalError?.error?.message ||
            errorData?.originalError?.reason ||
            rpcErrorMessage;

          throw new PureFIError(
            PureFIErrorCodes.PROVIDER,
            getMessageFromCode(code, fallbackMessage)
          );
        }

        payload = {
          message,
          signature,
        };
      } else if (
        isVerifyRuleNearLegacyPayload(param) ||
        isVerifyRuleNearPayload(param)
      ) {
        if (!isNearSigner(PureFI.signer)) {
          throw new PureFIError(
            PureFIErrorCodes.CONFIGURATION,
            'Signer/payload mismatch'
          );
        }

        const rulePayload = param as VerifyRuleNearPayload;
        const message = JSON.stringify(rulePayload);
        const bufferedMessage = Buffer.from(message);
        const uint8Message = new Uint8Array(bufferedMessage);
        let nearSignature: NearSignature;
        const nearSigner = PureFI.signer as INearSigner;

        try {
          nearSignature = await nearSigner.signMessage(uint8Message);
        } catch (error) {
          let errorMessage = 'Failed signing a message';

          if (error instanceof Error) {
            errorMessage = `${errorMessage}. ${error.message}`;
          }

          throw new PureFIError(PureFIErrorCodes.PROVIDER, errorMessage);
        }

        payload = {
          message: bufferedMessage,
          signature: nearSignature.signature,
          publicKey: nearSignature.publicKey,
        };
      }
    } else {
      throw new PureFIError(
        PureFIErrorCodes.VALIDATION,
        'Unsupported payload format provided'
      );
    }

    if (typeof payload === 'undefined') {
      throw new PureFIError(
        PureFIErrorCodes.VALIDATION,
        'Unsupported payload format provided'
      );
    }

    const isB2B = typeof projectId !== 'undefined';
    const isLegacy = isVerifyRuleLegacyPayload(payload);

    let url;
    let version;

    if (isB2B) {
      url = PureFI.backendUrlB2B;
      version = `v${isLegacy ? 1 : 2}`;
    } else {
      url = PureFI.backendUrl;
      version = `v${isLegacy ? 2 : 3}`;
    }

    const serviceUrl = `${url}/${version}`;
    const requestConfig: AxiosRequestConfig = {};

    if (isB2B) {
      requestConfig.headers = {
        'PUREFI-API-KEY': projectId,
      };

      requestConfig.withCredentials = true;
    }

    const result = await PureFI._verifyRule(payload, serviceUrl, requestConfig);
    return result;
  }

  private static async _verifyRule(
    payload: PureFIPayload,
    serviceUrl: string,
    config: AxiosRequestConfig
  ): Promise<VerifyRuleResponse> {
    try {
      const theAxios = axios.create(config);

      const response = await theAxios.post<
        PureFIPayload,
        AxiosResponse<VerifyRuleResponse>
      >(`${serviceUrl}/rule`, payload);

      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        if (axios.isAxiosError(error)) {
          const axiosError = error as AxiosError<ErrorResponse>;
          if (axiosError && axiosError.response) {
            const { data, status } = axiosError.response;
            const errorResponse = data as ErrorResponse;

            switch (status) {
              case 404: {
                switch (errorResponse.errorCode) {
                  case 90: {
                    throw new PureFIError(
                      PureFIErrorCodes.VALIDATION,
                      'Unsupported payload format provided'
                    );
                  }
                  case 91: {
                    throw new PureFIError(
                      PureFIErrorCodes.CONFIGURATION,
                      errorResponse.message
                    );
                  }
                  default: {
                    throw new PureFIError(
                      PureFIErrorCodes.INTERNAL,
                      'Unknown internal error occurred'
                    );
                  }
                }
              }
              case 400: {
                switch (errorResponse.errorCode) {
                  case 50: {
                    throw new PureFIError(
                      PureFIErrorCodes.FORBIDDEN,
                      `${errorResponse.message}.  Please, visit https://dashboard.purefi.io/kyc`
                    );
                  }
                  case 55: {
                    throw new PureFIError(
                      PureFIErrorCodes.FORBIDDEN,
                      `${errorResponse.message}. Please, visit https://dashboard.purefi.io/subscription`
                    );
                  }
                  case 54: {
                    throw new PureFIError(
                      PureFIErrorCodes.VALIDATION,
                      errorResponse.message
                    );
                  }
                  case 20: {
                    throw new PureFIError(
                      PureFIErrorCodes.VALIDATION,
                      errorResponse.message
                    );
                  }
                  case 130: {
                    throw new PureFIRuleError(
                      PureFIRuleErrorCodes.PARTIALLY_ELIGIBLE,
                      errorResponse.message
                    );
                  }
                  case 140: {
                    throw new PureFIRuleError(
                      PureFIRuleErrorCodes.NOT_ELIGIBLE,
                      errorResponse.message
                    );
                  }
                  case 150: {
                    throw new PureFIRuleError(
                      PureFIRuleErrorCodes.DEPOSIT_LIMIT,
                      errorResponse.message
                    );
                  }
                  default: {
                    throw new PureFIError(
                      PureFIErrorCodes.INTERNAL,
                      'Unknown error occurred'
                    );
                  }
                }
              }
              case 403: {
                throw new PureFIError(
                  PureFIErrorCodes.FORBIDDEN,
                  `${errorResponse.message}. Please, visit https://dashboard.purefi.io/subscription`
                );
              }
              default: {
                throw new PureFIError(
                  PureFIErrorCodes.INTERNAL,
                  'Internal error occurred'
                );
              }
            }
          } else {
            throw new PureFIError(
              PureFIErrorCodes.UNKNOWN,
              'Network error occurred'
            );
          }
        } else {
          throw new PureFIError(
            PureFIErrorCodes.UNKNOWN,
            'Unexpected error occurred'
          );
        }
      } else {
        throw new PureFIError(
          PureFIErrorCodes.UNKNOWN,
          'Unexpected error occurred'
        );
      }
    }
  }
}

export default PureFI;
