/**
 * Describes the type of {@link PureFIRuleError}
 */
enum PureFIRuleErrorCodes {
  /**
   * Indicates that address is not eligible against the rule
   */
  NOT_ELIGIBLE = 0,
  /**
   * Indicates that address is not eligible against the rule, but there are options to proceed
   */
  PARTIALLY_ELIGIBLE = 1,
  /**
   * Indicates that deposit limit exceeded
   */
  DEPOSIT_LIMIT = 2,
}

export default PureFIRuleErrorCodes;
