import { AddressType } from '../enums';

/**
 * Represents a data model that is used as a building block for {@link PureFIPayload}
 */
export type PureFIAddress = {
  address: string;
  type?: AddressType;
};

/**
 * Represents a model of payload that the issuer backend expects
 */
export type PureFIPayload = PureFIEthereumPayload | PureFINearPayload;

export type PureFIEthereumPayload = {
  /**
   * Stringified Array\<{@link PureFIAddress}\>
   *
   * Example:
   * ```typescript
   *   const message = JSON.stringify([
   *     {
   *       address: "0xbBbBBBBbbBBBbbbBbbBbbbbBBbBbbbbBbBbbBBbB",
   *       type: AddressType.WALLET,
   *     },
   *     {
   *       address: "0xCcCCccccCCCCcCCCCCCcCcCccCcCCCcCcccccccC"
   *     },
   *   ]);
   * ```
   */
  message: string;
  /**
   * When you sign a message you usually get a promise that resolves to a signature
   *
   * ```typescript
   *   const signature = await signer.signMessage(message);
   * ```
   *
   * More details at <a href="https://docs.ethers.io/v5/api/signer/#Signer-signMessage" target="_blank">ethers</a> or <a href="https://web3js.readthedocs.io/en/v1.2.11/web3-eth-accounts.html#sign" target="_blank">web3</a>
   */
  signature: string;
};

export enum KeyType {
  ED25519 = 0,
}

export type PublicKey = {
  data: Uint8Array;
  keyType: KeyType;
};

export type PureFINearPayload = {
  message: Uint8Array;
  signature: Uint8Array;
  publicKey: PublicKey;
};

export type NearSignature = {
  signature: Uint8Array;
  publicKey: PublicKey;
};

/**
 * Represents a data model that {@link PureFI.checkRisk} method returns
 *
 * Example:
 * ```typescript
 *   const response: = {
 *     address: "0xbBbBBBBbbBBBbbbBbbBbbbbBBbBbbbbBbBbbBBbB",
 *     riskScore: 37,
 *     connections: {
 *       atm: 0
 *       darkMarket: 0
 *       darkService: 0
 *       exchangeFraudulent: 0
 *       exchangeMlriskHigh: 50
 *       exchangeMlriskLow: 1
 *       exchangeMlriskModerate: 2
 *       exchangeMlriskVeryhigh: 0
 *       gambling: 0
 *       illegalService: 0
 *       marketplace: 0
 *       miner: 0
 *       mixer: 0
 *       p2pExchangeMlriskHigh: 48
 *       p2pExchangeMlriskLow: 0
 *       payment: 0
 *       ransom: 0
 *       scam: 0
 *       stolenCoins: 0
 *       wallet: 0,
 *       childExploitation: 0,
 *       sanctions: 0,
 *       seizedAssets: 0,
 *       terrorismFinancing: 0,
 *       liquidityPools: 0,
 *       other: 0,
 *     },
 *   };
 * ```
 */
export type CheckRiskResponse = {
  /**
   * The address that was screened
   *
   * This property could be useful if you {@link PureFI.checkRisk | checked risks} of a list of addresses
   */
  address: string;
  /**
   * AML risk value in % that represents how risky this address is from PureFI perspective
   *
   * Possible values lay in range [0..100]
   *
   * 0 indicates the lowest risks, 100 - the highest
   */
  riskScore: number;
  /**
   * This field holds a **key-value** pairs
   *
   * Where **key** is a name of connection
   *
   * And **value** indicates which part of total {@link riskScore} this connection represents in %
   *
   * Could be an empty object \{\} if no connections found
   */
  connections: Record<string, number>;
};

/**
 * Represents a data model that {@link PureFI.downloadReport} method returns
 */
export type DownloadReportResponse = {
  /**
   * Array of bytes to download
   */
  buffer: ArrayBuffer;
};

export type ErrorResponse = {
  errorCode: number;
  message: string;
  details?: Array<Record<string, any>>;
};

export type VerifyRuleEthereumLegacyPayload = {
  address: string;
  ruleId: string;
  chainId: number;
};

export type VerifyRuleNearLegacyPayload = {
  address: string;
  ruleId: string;
  chainId: 'near';
};

export type VerifyRuleEthereumPayload = {
  sender: string;
  ruleId: string;
  chainId: number;
  receiver?: string;
  token?: string;
  amount?: string;
};

export type VerifyRuleNearPayload = {
  sender: string;
  ruleId: string;
  chainId: 'near';
  receiver?: string;
  token?: string;
  amount?: string;
};

export type VerifyRulePayload =
  | VerifyRuleEthereumPayload
  | VerifyRuleEthereumLegacyPayload
  | VerifyRuleNearPayload
  | VerifyRuleNearLegacyPayload;

export type VerifyRuleEthereumLegacyResponse = {
  data: FixedLengthArray<[string, string, string, string]>;
  signature: string;
};

export type VerifyRuleNearLegacyResponse = {
  data: {
    session_id: string;
    rule_id: string;
    verification_timestamp: number;
    verified_wallet: string;
  };
  signature: Uint8Array;
  v: 0 | 1 | 2 | 3;
};

export type VerifyRuleEthereumResponse = string;

export type VerifyRuleNearResponse = string;

export type VerifyRuleResponse =
  | VerifyRuleEthereumResponse
  | VerifyRuleEthereumLegacyResponse
  | VerifyRuleNearResponse
  | VerifyRuleNearLegacyResponse;

export type FixedLengthArray<T extends any[]> = Pick<
  T,
  Exclude<keyof T, ArrayLengthMutationKeys>
> & { [Symbol.iterator]: () => IterableIterator<ArrayItems<T>> };

type ArrayLengthMutationKeys =
  | 'splice'
  | 'push'
  | 'pop'
  | 'shift'
  | 'unshift'
  | number;

type ArrayItems<T extends Array<any>> = T extends Array<infer TItems>
  ? TItems
  : never;
