/**
 * Describes the type of {@link PureFIError}
 */
enum PureFIErrorCodes {
  /**
   * Indicates a configuration related error
   */
  CONFIGURATION = 0,
  /**
   * Indicates a payload related error
   */
  VALIDATION = 1,
  /**
   * Indicates a provider related error
   */
  PROVIDER = 2,
  /**
   * Indicates the lack of PureFI tokens on the account that signed a message
   */
  FORBIDDEN = 3,
  /**
   * Indicates a problem with the request at the issuer backend side
   */
  INTERNAL = 4,
  /**
   * Indicates that something unexpected happened, e.g. network errors
   */
  UNKNOWN = 5,
}

export default PureFIErrorCodes;
