import { PureFIRuleErrorCodes } from '../enums';

/**
 * Represents an error that can be thrown during rule verification process
 *
 * Basically extends native Error class with the **code** property that highlights {@link PureFIRuleErrorCodes | what} went wrong
 */
class PureFIRuleError extends Error {
  public code: PureFIRuleErrorCodes;

  constructor(
    code: PureFIRuleErrorCodes,
    message = 'Unexpected error occured'
  ) {
    super(message);
    this.code = code;
    this.name = 'PureFIRuleError';
    const actualProto = new.target.prototype;

    if (Object.setPrototypeOf) {
      Object.setPrototypeOf(this, actualProto);
    }
  }
}

export default PureFIRuleError;
