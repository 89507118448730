import { ISigner, IEthereumSigner, INearSigner } from '../signers';

import {
  PureFIAddress,
  PureFIPayload,
  PureFIEthereumPayload,
  PureFINearPayload,
  VerifyRulePayload,
  KeyType,
  VerifyRuleEthereumPayload,
  VerifyRuleNearPayload,
  NearSignature,
  VerifyRuleEthereumLegacyPayload,
  VerifyRuleNearLegacyPayload,
} from '../types';

function isSigner<T = any, V = any>(obj: any): obj is ISigner<T, V> {
  return 'signMessage' in obj && typeof obj.signMessage === 'function';
}

function isEthereumSigner(obj: any): obj is IEthereumSigner {
  return isSigner<string, string>(obj);
}

function isNearSigner(obj: any): obj is INearSigner {
  return isSigner<Uint8Array, NearSignature>(obj);
}

function isPureFIAddress(obj: any): obj is PureFIAddress {
  return 'address' in obj && typeof obj.address === 'string';
}

function isPureFIEthereumPayload(obj: any): obj is PureFIEthereumPayload {
  return (
    'message' in obj &&
    'signature' in obj &&
    typeof obj.message === 'string' &&
    typeof obj.signature === 'string'
  );
}

function isPureFINearPayload(obj: any): obj is PureFINearPayload {
  const TypedArray = Object.getPrototypeOf(Uint8Array);
  return (
    'message' in obj &&
    'signature' in obj &&
    'publicKey' in obj &&
    obj.message instanceof TypedArray &&
    obj.signature instanceof TypedArray &&
    'data' in obj.publicKey &&
    'keyType' in obj.publicKey &&
    obj.publicKey.data instanceof TypedArray &&
    Object.values(KeyType).includes(obj.publicKey.keyType)
  );
}

function isEthereumSignature(obj: any): obj is string {
  return typeof obj === 'string';
}

function isNearSignature(obj: any): obj is NearSignature {
  const TypedArray = Object.getPrototypeOf(Uint8Array);

  return (
    'signature' in obj &&
    'publicKey' in obj &&
    obj.signature instanceof TypedArray &&
    'data' in obj.publicKey &&
    'keyType' in obj.publicKey &&
    obj.publicKey.data instanceof TypedArray &&
    Object.values(KeyType).includes(obj.publicKey.keyType)
  );
}

function isPureFIPayload(obj: any): obj is PureFIPayload {
  return isPureFIEthereumPayload(obj) || isPureFINearPayload(obj);
}

function isVerifyRuleEthereumLegacyPayload(
  obj: any
): obj is VerifyRuleEthereumLegacyPayload {
  return (
    'address' in obj &&
    'ruleId' in obj &&
    'chainId' in obj &&
    typeof obj.address === 'string' &&
    typeof obj.ruleId === 'string' &&
    typeof obj.chainId === 'number'
  );
}

function isVerifyRuleNearLegacyPayload(
  obj: any
): obj is VerifyRuleNearLegacyPayload {
  return (
    'address' in obj &&
    'ruleId' in obj &&
    'chainId' in obj &&
    typeof obj.address === 'string' &&
    typeof obj.ruleId === 'string' &&
    typeof obj.chainId === 'string' &&
    obj.chainId === 'near'
  );
}

function isVerifyRuleEthereumPayload(
  obj: any
): obj is VerifyRuleEthereumPayload {
  return (
    'sender' in obj &&
    'ruleId' in obj &&
    'chainId' in obj &&
    typeof obj.sender === 'string' &&
    typeof obj.ruleId === 'string' &&
    typeof obj.chainId === 'number'
  );
}

function isVerifyRuleNearPayload(obj: any): obj is VerifyRuleNearPayload {
  return (
    'sender' in obj &&
    'ruleId' in obj &&
    'chainId' in obj &&
    typeof obj.sender === 'string' &&
    typeof obj.ruleId === 'string' &&
    typeof obj.chainId === 'string' &&
    obj.chainId === 'near'
  );
}

function isVerifyRulePayload(obj: any): obj is VerifyRulePayload {
  return (
    isVerifyRuleEthereumLegacyPayload(obj) ||
    isVerifyRuleNearLegacyPayload(obj) ||
    isVerifyRuleEthereumPayload(obj) ||
    isVerifyRuleNearPayload(obj)
  );
}

function isVerifyRuleLegacyPayload(obj: PureFIPayload): boolean {
  let parsedObject;

  if (isPureFIEthereumPayload(obj)) {
    parsedObject = JSON.parse(obj.message);
  } else {
    // isPureFINearPayload
    parsedObject = JSON.parse(obj.message.toString());
  }

  return (
    isVerifyRuleEthereumLegacyPayload(parsedObject) ||
    isVerifyRuleNearLegacyPayload(parsedObject)
  );
}

export {
  isSigner,
  isEthereumSigner,
  isNearSigner,
  isPureFIPayload,
  isPureFIAddress,
  isPureFIEthereumPayload,
  isPureFINearPayload,
  isVerifyRulePayload,
  isVerifyRuleEthereumLegacyPayload,
  isVerifyRuleNearLegacyPayload,
  isVerifyRuleEthereumPayload,
  isVerifyRuleNearPayload,
  isVerifyRuleLegacyPayload,
};
