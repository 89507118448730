/**
 * Describes the type of address
 */
enum AddressType {
  /**
   * Indicates that the address represents a wallet
   */
  WALLET = 'wallet',
  /**
   * Indicates that the address represents a contract such as a farming pool, etc.
   */
  CONTRACT = 'contract',
}

export default AddressType;
