import { PureFIErrorCodes } from '../enums';

/**
 * Represents an error that can be thrown during AML risk screening or pdf report generation process
 *
 * Basically extends native Error class with the **code** property that highlights {@link PureFIErrorCodes | what} went wrong
 */
class PureFIError extends Error {
  public code: PureFIErrorCodes;

  constructor(code: PureFIErrorCodes, message = 'Unexpected error occured') {
    super(message);
    this.code = code;
    this.name = 'PureFIError';
    const actualProto = new.target.prototype;

    if (Object.setPrototypeOf) {
      Object.setPrototypeOf(this, actualProto);
    }
  }
}

export default PureFIError;
